<template>
  <div v-loading="pageLoading" ref="userManagement" class="user-management">
    <template v-if="channnelList.length">
      <div ref="functionButtons" class="function-buttons">
        <el-button
          v-if="listFind('新增')"
          type="primary"
          size="small"
          @click="onAddUser"
          >新增</el-button
        >
      </div>
      <div ref="form" class="form-area">
        <el-form
          :inline="true"
          :model="form"
          label-position="left"
          label-width="72px"
          class="demo-form-inline"
        >
          <el-form-item label="司机姓名：">
            <el-input
              v-model.trim="form.userName"
              clearable
              placeholder="请输入司机姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证号：">
            <el-input
              v-model.trim="form.idCardNo"
              clearable
              placeholder="请输入身份证号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码：">
            <el-input
              v-model.trim="form.phone"
              clearable
              placeholder="请输入登录手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="司机类型：">
            <el-select
              v-model="form.driveType"
              clearable
              placeholder="请选择司机类型"
            >
              <el-option
                v-for="role in driveTypeList"
                :key="role.value"
                :label="role.label"
                :value="role.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属公司：">
            <companySelect
              :clearable="true"
              :company-tree="companyList"
              :valuename="valuename"
              :value="value"
              @getValue="getGroupId"
            ></companySelect>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="small"
              :loading="searchLoading"
              @click="onSearch()"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <Table
        :table-data="tableData"
        :title-name="titleName"
        :col-class-type="true"
        :operation="true"
        :table-height="tableHeight"
        operation-width="160"
      >
        <template slot-scope="scope">
          <template v-if="listFind('编辑')">
            <el-button
              v-if="scope.scopeRow.status === 0"
              sort="primary"
              type="text"
              size="small"
              @click="onUpdateUser(scope.scopeRow)"
              >编辑</el-button
            >
          </template>
          <el-button
            v-if="
              scope.scopeRow.status === 0
                ? listFind('禁用')
                  ? true
                  : false
                : scope.scopeRow.status === 1
                ? listFind('启用')
                  ? true
                  : false
                : false
            "
            type="text"
            size="small"
            :sort="scope.scopeRow.status === 0 ? 'danger' : 'primary'"
            @click="onDisableUser(scope.scopeRow)"
            >{{ scope.scopeRow.status === 0 ? "禁用" : "启用" }}</el-button
          >
          <template v-if="listFind('重置密码')">
            <el-button
              v-if="scope.scopeRow.status === 0"
              type="text"
              size="small"
              sort="danger"
              @click="onResetPassword(scope.scopeRow)"
              >重置密码</el-button
            >
          </template>
        </template>
      </Table>
      <div ref="pagination" class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="form.pageSize"
          :current-page="form.currentPage"
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        ></el-pagination>
      </div>
      <!-- 新增|编辑 -->
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="1100px"
        :close-on-click-modal="false"
      >
        <editForm
          ref="addForm"
          :companyList="companyList"
          @saveOk="getDataList"
          @cancel="dialogVisible = false"
        />
      </el-dialog>
    </template>
    <div
      v-else-if="emptyFlag"
      style="width: 100%; font-size: 18px; text-align: center"
    >
      <img src="~@/assets/images/home/empty.png" alt />
      <div>暂无权限，请联系管理员</div>
    </div>
  </div>
</template>

<script>
import { checkPhone, checkName } from "@/common/utils/index.js";
import editForm from "./editDriverForm/editDriverForm.vue";
import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  getDriverList,
  disableDriver,
  resetDriverPwd,
  getCompanyTree,
  getCurrentUserChannelInfoAPI,
} from "@/api/lib/api.js";

export default {
  name: "userManagement",
  components: {
    companySelect,
    editForm,
  },
  data() {
    return {
      pageLoading: true,
      emptyFlag: false,
      channnelList: [],
      phoneDisabled: false,
      titleName: [
        {
          title: "登录手机号码",
          props: "loginPhone",
        },
        {
          title: "联系号码",
          props: "phone",
        },
        {
          title: "司机姓名",
          props: "userName",
        },
        {
          title: "身份证号码",
          props: "idCardNo",
        },
        {
          title: "联系地址",
          props: "address",
        },
        {
          title: "司机类型",
          props: "driveType",
          SpecialJudgment: (res) => {
            let text = "";
            if (res) {
              for (let i = 0; i < res.length; i++) {
                for (let j = 0; j < this.driveTypeList.length; j++) {
                  if (this.driveTypeList[j].value === res[i]) {
                    text += this.driveTypeList[j].label + "、";
                    break;
                  }
                }
              }
              return text.substr(0, text.length - 1);
            } else {
              return "";
            }
          },
        },
        {
          title: "所属企业",
          props: "companyName",
        },
      ],
      tableHeight: 0,
      tableData: [],
      value: null,
      valuename: null,
      valueAddName: null,
      valueAdd: null,
      form: {
        userName: null,
        idCardNo: null,
        companyIds: null,
        phone: null,
        driveType: null,
        currentPage: 1,
        pageSize: 10,
      },
      addForm: {
        id: "",
        phone: "",
        userName: "",
        idCardType: "",
        idCardNo: "",
        address: "",
        companyIds: [],
        driveType: [],
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
          { validator: checkName, trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
        loginPhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
        idCardNo: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
        ],
        driveType: [
          { required: true, message: "请输入司机类型", trigger: "change" },
        ],
        companyIds: [
          { required: true, message: "请选择所属公司", trigger: "change" },
        ],
      },
      total: 0,
      dialogVisible: false,
      title: "",
      driveTypeList: [
        { value: 0, label: "定制客运司机" },
        { value: 1, label: "包车司机" },
        { value: 2, label: "定制班线司机" },
        { value: 3, label: "村通客运司机" },
        { value: 4, label: "班线客运司机" },
      ],
      companyList: [],
      authCompanyList: [],
      availableUserGroupList: [],
      roleList: [],
      typeList: [],
      availableRoleList: [],
      searchLoading: false,
      editLoading: false,
      userId: null,
    };
  },

  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.userManagement?.clientHeight;
      const buttonsHeight = this.$refs.functionButtons?.clientHeight;
      const formHeight = this.$refs.form?.clientHeight;
      const paginationHeight = this.$refs.pagination?.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        36;
    },
    // 点击查询
    onSearch() {
      this.searchLoading = true;
      this.form.currentPage = 1;
      this.getDataList();
    },

    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.getDataList();
    },

    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
      this.form.currentPage = 1;
      this.getDataList();
      this.computeHeight();
    },
    // 新增用户
    onAddUser() {
      // this.phoneDisabled = false;
      this.title = "新增用户";
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.addForm?.loadInfo();
        this.$refs.addForm?.queryChannelListFun();
      });
      // this.valueAdd = null;
      // this.valueAddName = "";
      // if (this.$refs.addForm) this.$refs.addForm.clearValidate();
      // this.addForm.companyIds = null;
    },
    // 点击表格编辑按钮
    onUpdateUser(row) {
      console.log(row);
      this.dialogVisible = true;
      this.title = "修改用户信息";
      this.$nextTick(() => {
        this.$refs.addForm?.loadInfo(row);
        this.$refs.addForm?.queryChannelListFun();
      });
    },

    // 重置密码
    onResetPassword(row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "您将重置用户 "),
          h("span", { style: "color: #059370" }, row.userName),
          h("span", null, "  的密码为"),
          h("span", { style: "color: #059370" }, "123456"),
          h("span", null, "，是否重置？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          resetDriverPwd({ id: row.id }).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message({
                type: "success",
                showClose: true,
                message: "重置密码成功!",
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消重置密码",
          });
        });
    },

    // 禁用|启用 用户
    onDisableUser(row) {
      if (row.status === 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "消息",
          type: "warning",
          message: h("p", null, [
            h("span", null, "确定禁用用户 "),
            h("span", { style: "color: #059370" }, row.userName),
            h("span", null, " ？"),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            disableDriver({ id: row.id, status: 1 }).then((res) => {
              if (res.code === "SUCCESS") {
                this.$message.success("禁用成功");
                row.status = 1;
                if (sessionStorage.getItem("username") === row.loginName) {
                  sessionStorage.removeItem("token");
                  location.reload();
                }
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              showClose: true,
              message: "已取消禁用",
            });
          });
      } else {
        disableDriver({ id: row.id, status: 0 }).then((res) => {
          if (res.code === "SUCCESS") {
            this.$message.success("启用成功");
            row.status = 0;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 获取用户列表
    getDataList() {
      this.dialogVisible = false;
      getDriverList(this.form).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
          this.tableData = [];
        }
        this.searchLoading = false;
      });
    },
    //
    getGroupId(v) {
      if (v == null) {
        this.form.companyIds = [];
      } else {
        this.form.companyIds = [v];
      }
    },

    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
  },
  created() {
    getCurrentUserChannelInfoAPI().then((res) => {
      if (res.code === "SUCCESS") {
        this.channnelList = res.data;
        if (!res.data.length) {
          this.$message.error("暂无权限，请联系管理员");
          this.emptyFlag = true;
          this.pageLoading = false;
          return;
        }
        this.pageLoading = false;
        this.onSearch();
        this.getCompanyList();
      } else {
        this.pageLoading = false;
        this.$message.error(res.msg);
      }
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.Table {
  margin-top: 16px;
}
.user-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-project-gps {
  .user-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
