<template>
  <div class="edit-driver-form">
    <el-form
      ref="form"
      :model="addForm"
      :rules="rules"
      label-width="110px"
      label-position="right"
      class="demo-form dialog-form"
    >
      <h3>基本信息</h3>
      <el-row>
        <el-col :span="12">
          <el-form-item label="登录手机号码：" prop="loginPhone">
            <el-input
              v-model.trim="addForm.loginPhone"
              placeholder="请输入登录手机号码"
              maxlength="11"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系号码：" prop="phone">
            <el-input
              v-model.trim="addForm.phone"
              maxlength="11"
              placeholder="请输入联系手机号码"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="司机姓名：" prop="userName">
            <el-input
              v-model.trim="addForm.userName"
              placeholder="请输入司机姓名"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份证号码：" prop="idCardNo">
            <el-input
              v-model.trim="addForm.idCardNo"
              placeholder="请输入身份证号码"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系地址：" prop="address">
            <el-input
              v-model.trim="addForm.address"
              placeholder="请输入联系地址"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="司机类型：" prop="driveType">
            <el-select
              v-model="addForm.driveType"
              :multiple-limit="1"
              clearable
              multiple
              placeholder="请选择司机类型"
            >
              <el-option
                v-for="role in driveTypeList"
                :key="role.value"
                :label="role.label"
                :value="role.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属公司：" prop="companyIds">
            <companySelect
              :clearable="true"
              :company-tree="companyList"
              :valuename="valueAddName"
              :value="valueAdd"
              @getValue="getAddGroupId"
              @getName="getName"
            ></companySelect>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="渠道：" prop="channelNo">
            <el-select
              v-model="addForm.channelNo"
              :multiple-limit="1"
              clearable
              placeholder="请选择渠道"
            >
              <el-option
                v-for="role in channnelList"
                :key="role.channelNo"
                :label="role.channelName"
                :value="role.channelNo"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="驾驶员照片：">
            <div style="display: flex">
              <el-image
                v-if="addForm.driverPhoto"
                :src="imgUrl + addForm.driverPhoto"
                :preview-src-list="[imgUrl + addForm.driverPhoto]"
                fit="cover"
              ></el-image>
              <el-upload
                name="files"
                :action="uploadUrl"
                :multiple="false"
                :before-upload="beforeUpload"
                :on-success="uploadSuccess"
                :on-error="uploadError"
                :show-file-list="false"
              >
                <div class="imgUpload">
                  <i class="el-icon-plus"></i>
                </div>
              </el-upload>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <h3>从业资格证信息</h3>
      <el-row>
        <el-col :span="8">
          <el-form-item label="资格证号：" prop="jobSeniority">
            <el-input
              v-model="addForm.jobSeniority"
              :disabled="!!addForm.id"
              placeholder="请填写资格证号"
              maxlength="100"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- :disabled="addForm.id" -->
          <el-form-item label="到期时间：" prop="jobSeniorityValidity">
            <el-date-picker
              v-model="addForm.jobSeniorityValidity"
              placeholder="请选择日期"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <h3>驾驶证信息</h3>
      <el-row>
        <el-col :span="8">
          <el-form-item label="驾驶证号码：" prop="driverLicense">
            <el-input
              v-model="addForm.driverLicense"
              placeholder="请填写资格证号"
              maxlength="50"
              :disabled="!!addForm.id"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="到期时间：" prop="driverLicenseValidity">
            <el-date-picker
              v-model="addForm.driverLicenseValidity"
              placeholder="请选择日期"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="驾驶证类型：" prop="driverLicenseType">
            <el-select clearable v-model="addForm.driverLicenseType" multiple>
              <el-option
                v-for="item in drivingTypeList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="default" size="small" @click="$emit('cancel')"
        >取消</el-button
      >
      <el-button
        type="primary"
        size="small"
        :loading="editLoading"
        @click="onSave"
        >保存</el-button
      >
    </span>
  </div>
</template>

<script>
import {
  checkPhone,
  checkName,
  checkIdentificationNumber,
} from "@/common/utils/index.js";
import {
  insertDriver,
  updateDriver,
  queryDictsByCodes,
  getCurrentUserChannelInfoAPI,
} from "@/api/lib/api.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
import config from "@/api/static/host.js";
export default {
  props: ["companyList"],
  components: { companySelect },
  data() {
    return {
      uploadUrl: config.apiBaseUrl + "manage/uploadImage/file/upload",
      imgUrl: config.imgURL,
      phoneDisabled: false,
      valueAddName: "",
      valueAdd: 0,
      drivingTypeList: [{ dictCode: "1", dictValue: "A1" }],
      addForm: {
        id: "",
        phone: "",
        userName: "",
        idCardType: "",
        idCardNo: "",
        address: "",
        jobSeniority: "", //从业资格证号
        jobSeniorityValidity: "",
        driverLicense: "", //驾驶证资格证号
        driverLicenseValidity: "",
        driverLicenseType: [],
        companyIds: [],
        driveType: [],
        driverPhoto: "",
        channelNo: "",
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
          { validator: checkName, trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
        loginPhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
        idCardNo: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
          { validator: checkIdentificationNumber, trigger: "blur" },
        ],
        driveType: [
          { required: true, message: "请输入司机类型", trigger: "change" },
        ],
        companyIds: [
          { required: true, message: "请选择所属公司", trigger: "change" },
        ],
        channelNo: [
          { required: true, message: "请选择渠道", trigger: "change" },
        ],
      },
      driveTypeList: [
        { value: 0, label: "定制客运司机" },
        { value: 1, label: "包车司机" },
        { value: 2, label: "定制班线司机" },
        { value: 3, label: "村通客运司机" },
        { value: 4, label: "班线客运司机" },
      ],
      editLoading: false,
      channnelList: [],
    };
  },
  watch: {
    //同步基本信息填写的身份证号码
    "addForm.idCardNo"(val) {
      this.addForm.jobSeniority = val;
      this.addForm.driverLicense = val;
    },
  },
  mounted() {
    this.getDicts();
  },
  methods: {
    queryChannelListFun() {
      getCurrentUserChannelInfoAPI().then((res) => {
        if (res.code === "SUCCESS") {
          this.channnelList = res.data;
          if (res.data.length === 1) {
            this.addForm.channelNo = res.data[0].channelNo;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    loadInfo(info) {
      this.valueAdd = null;
      this.valueAddName = "";
      this.phoneDisabled = false;
      this.addForm = {
        id: "",
        phone: "",
        userName: "",
        idCardType: "",
        idCardNo: "",
        address: "",
        jobSeniority: "",
        jobSeniorityValidity: "",
        driverLicense: "",
        driverLicenseValidity: "",
        driverLicenseType: [],
        companyIds: [],
        driveType: [],
        driverPhoto: "",
        channelNo: "",
        loginPhone: "",
      };
      this.$nextTick(() => {
        this.$refs.form?.clearValidate();
      });
      this.handelInfo(info);
    },
    handelInfo(row) {
      if (!row) return;
      this.phoneDisabled = true;
      row.driverLicenseType.forEach((elem, index) => {
        row.driverLicenseType[index] = elem + "";
      });
      Object.assign(this.addForm, row);
      this.addForm.companyIds = [row.companyId];
      this.addForm.driveType = row.driveType.slice(0, 1);
      this.$nextTick(() => {
        this.valueAddName = row.companyName;
        this.valueAdd = row.companyId;
      });
    },
    // 文件校验
    beforeUpload(file) {
      if (file.size > 5 * 1024 * 1024) {
        this.$message.warning("图片不能大于5M ,请重新选择");
        return false;
      }
      return true;
    },
    // 图片上传成功回调
    uploadSuccess(res, file) {
      this.$set(this.addForm, "driverPhoto", res.data[0]);
    },
    //
    uploadError(res) {
      this.$message.error(res);
    },
    // 新增/保存用户
    onSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.editLoading = true;
          this.addForm.companyId = this.addForm.companyIds[0];
          if (!this.addForm.id) {
            insertDriver(this.addForm)
              .then((res) => {
                this.editLoading = false;
                if (res.code === "SUCCESS") {
                  this.$message.success("新增成功");
                  this.$emit("saveOk");
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.editLoading = false;
              });
          } else {
            updateDriver(this.addForm)
              .then((res) => {
                this.editLoading = false;
                if (res.code === "SUCCESS") {
                  this.$message.success("修改成功");
                  this.$emit("saveOk");
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.editLoading = false;
              });
          }
        }
      });
    },
    // 获取驾驶证类型字典
    getDicts() {
      queryDictsByCodes().then((res) => {
        if (res.code === "SUCCESS") {
          this.drivingTypeList = res.data ?? [];
        }
      });
    },
    getName(val) {
      this.addForm.companyName = val;
    },
    //
    getAddGroupId(v) {
      if (v == null) {
        this.addForm.companyIds = [];
      } else {
        this.addForm.companyIds = [v];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-driver-form {
  .el-form {
    padding: 0 20px;
    box-sizing: border-box;
    width: 100%;

    overflow-y: auto;
    overflow-x: hidden;
    max-height: 60vh;
    /deep/ .el-form-item__label {
      line-height: 36px !important;
    }
    .el-row {
      padding-left: 50px;
    }
    .imgUpload {
      width: 80px;
      height: 80px;
      border: 1px dotted #ccc;
      border-radius: 10px;
      text-align: center;
      line-height: 80px;
    }
    .el-image {
      width: 80px;
      height: 80px;
      margin-right: 10px;
    }
  }
  .dialog-footer {
    padding: 20px 0 0 0;
    display: block;
    text-align: right;
  }
}
</style>
