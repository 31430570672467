var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-driver-form" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form dialog-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            "label-width": "110px",
            "label-position": "right",
          },
        },
        [
          _c("h3", [_vm._v("基本信息")]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "登录手机号码：", prop: "loginPhone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入登录手机号码",
                          maxlength: "11",
                        },
                        model: {
                          value: _vm.addForm.loginPhone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addForm,
                              "loginPhone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addForm.loginPhone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系号码：", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "11",
                          placeholder: "请输入联系手机号码",
                        },
                        model: {
                          value: _vm.addForm.phone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addForm,
                              "phone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addForm.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "司机姓名：", prop: "userName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入司机姓名" },
                        model: {
                          value: _vm.addForm.userName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addForm,
                              "userName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addForm.userName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证号码：", prop: "idCardNo" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入身份证号码" },
                        model: {
                          value: _vm.addForm.idCardNo,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addForm,
                              "idCardNo",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addForm.idCardNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系地址：", prop: "address" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入联系地址" },
                        model: {
                          value: _vm.addForm.address,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addForm,
                              "address",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addForm.address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "司机类型：", prop: "driveType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            "multiple-limit": 1,
                            clearable: "",
                            multiple: "",
                            placeholder: "请选择司机类型",
                          },
                          model: {
                            value: _vm.addForm.driveType,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "driveType", $$v)
                            },
                            expression: "addForm.driveType",
                          },
                        },
                        _vm._l(_vm.driveTypeList, function (role) {
                          return _c("el-option", {
                            key: role.value,
                            attrs: { label: role.label, value: role.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属公司：", prop: "companyIds" } },
                    [
                      _c("companySelect", {
                        attrs: {
                          clearable: true,
                          "company-tree": _vm.companyList,
                          valuename: _vm.valueAddName,
                          value: _vm.valueAdd,
                        },
                        on: {
                          getValue: _vm.getAddGroupId,
                          getName: _vm.getName,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "渠道：", prop: "channelNo" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            "multiple-limit": 1,
                            clearable: "",
                            placeholder: "请选择渠道",
                          },
                          model: {
                            value: _vm.addForm.channelNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "channelNo", $$v)
                            },
                            expression: "addForm.channelNo",
                          },
                        },
                        _vm._l(_vm.channnelList, function (role) {
                          return _c("el-option", {
                            key: role.channelNo,
                            attrs: {
                              label: role.channelName,
                              value: role.channelNo,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-form-item", { attrs: { label: "驾驶员照片：" } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _vm.addForm.driverPhoto
                          ? _c("el-image", {
                              attrs: {
                                src: _vm.imgUrl + _vm.addForm.driverPhoto,
                                "preview-src-list": [
                                  _vm.imgUrl + _vm.addForm.driverPhoto,
                                ],
                                fit: "cover",
                              },
                            })
                          : _vm._e(),
                        _c(
                          "el-upload",
                          {
                            attrs: {
                              name: "files",
                              action: _vm.uploadUrl,
                              multiple: false,
                              "before-upload": _vm.beforeUpload,
                              "on-success": _vm.uploadSuccess,
                              "on-error": _vm.uploadError,
                              "show-file-list": false,
                            },
                          },
                          [
                            _c("div", { staticClass: "imgUpload" }, [
                              _c("i", { staticClass: "el-icon-plus" }),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("h3", [_vm._v("从业资格证信息")]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "资格证号：", prop: "jobSeniority" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: !!_vm.addForm.id,
                          placeholder: "请填写资格证号",
                          maxlength: "100",
                        },
                        model: {
                          value: _vm.addForm.jobSeniority,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "jobSeniority", $$v)
                          },
                          expression: "addForm.jobSeniority",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "到期时间：",
                        prop: "jobSeniorityValidity",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: { placeholder: "请选择日期" },
                        model: {
                          value: _vm.addForm.jobSeniorityValidity,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "jobSeniorityValidity", $$v)
                          },
                          expression: "addForm.jobSeniorityValidity",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("h3", [_vm._v("驾驶证信息")]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "驾驶证号码：", prop: "driverLicense" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请填写资格证号",
                          maxlength: "50",
                          disabled: !!_vm.addForm.id,
                        },
                        model: {
                          value: _vm.addForm.driverLicense,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "driverLicense", $$v)
                          },
                          expression: "addForm.driverLicense",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "到期时间：",
                        prop: "driverLicenseValidity",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: { placeholder: "请选择日期" },
                        model: {
                          value: _vm.addForm.driverLicenseValidity,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "driverLicenseValidity", $$v)
                          },
                          expression: "addForm.driverLicenseValidity",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "驾驶证类型：",
                        prop: "driverLicenseType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", multiple: "" },
                          model: {
                            value: _vm.addForm.driverLicenseType,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "driverLicenseType", $$v)
                            },
                            expression: "addForm.driverLicenseType",
                          },
                        },
                        _vm._l(_vm.drivingTypeList, function (item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              label: item.dictValue,
                              value: item.dictCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                loading: _vm.editLoading,
              },
              on: { click: _vm.onSave },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }